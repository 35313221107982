import { LOGIN_ACTIONS } from '../actions/login.actions';
import { errorMessageHandler } from '../services/error.service';
import { IUserData } from '../../models/main/user.model';

const initialState = {
    userData: <IUserData>{},
    userRights: <string[]>[],
    keycloakData: <any>{},
    isLoginPending: <boolean>false,
    isRestoringSession: <boolean>true,
    isLogged: <boolean>false,
    isError: <boolean>false,
    errorCode: <number|null>null,
    uniqueErrorCode: <string|null>null,
    errorMessage: <string|null>null
}

export default function loginReducer(state = initialState, action: any) {
    switch (action.type) {
        // STATES
        case LOGIN_ACTIONS.LOGIN_PENDING: {
          return {
            ...state,
            isLoginPending: true
          }
        }
        case LOGIN_ACTIONS.KEYCLOAK_AUTH_SUCCESSFULL: {
          return {
            ...state,
            isLoginPending: true,
            keycloakData: action.payload
          }
        }
        case LOGIN_ACTIONS.LOGIN_SUCCESSFULL: {
          return {
            ...state,
            userData: action.payload,
            userRights: action.payload.rights,
            isLogged: true,
            isLoginPending: false,
          }
        }
        case LOGIN_ACTIONS.LOGIN_FAILED: {
          return {
            ...state,
            isLoginPending: false,
            isError: true,
            isLogged: false,
            errorCode: action.payload?.data.httpErrorCode,
            uniqueErrorCode: action.payload?.data.uniqueErrorCode,
            errorMessage: errorMessageHandler(action.payload?.data.errorId),
          }
        }
        case LOGIN_ACTIONS.GET_ACTIVE_SESSION_PENDING: {
          return {
            ...state,
            isRestoringSession: true
          }
        }
        case LOGIN_ACTIONS.GET_ACTIVE_SESSION_SUCCESSFULL: {
          return {
            ...state,
            isRestoringSession: false,
            isLogged: true,
            userData: action.payload,
            userRights: action.payload.rights,
          }
        }
        case LOGIN_ACTIONS.GET_ACTIVE_SESSION_FAILED: {
          return {
            ...state,
            isLoginPending: false,
            isRestoringSession: false,
            isLogged: false,
            isError: action.payload?.data.errorId !== 302001,
            errorCode: action.payload?.data.httpErrorCode,
            uniqueErrorCode: action.payload?.data.uniqueErrorCode,
            errorMessage: errorMessageHandler(action.payload?.data.errorId),
          }
        }
        case LOGIN_ACTIONS.USER_LOGOUT: {
          return {
            ...initialState,
            isRestoringSession: true,
          }
        }
        case LOGIN_ACTIONS.USER_LOGOUT_FAILED: {
          return {
            ...initialState,
            isRestoringSession: false,
          }
        }
        case LOGIN_ACTIONS.USER_CHANGE_LANGUAGE_SUCCESS: {
          return {
            ...state,
            isError: false,
            userData: {
              ...state.userData,
              language: action.payload
            }
          }
        }
        case LOGIN_ACTIONS.USER_CHANGE_LANGUAGE_FAILED: {
          return {
            ...state,
            isError: true,
            errorCode: action.payload?.data.httpErrorCode,
            uniqueErrorCode: action.payload?.data.uniqueErrorCode,
          }
        }
        case LOGIN_ACTIONS.HANDLE_LOGOUT_MULTIPLE_TABS: {
          return {
            ...initialState,
          }
        }
        default:
          return state;
    }
}