import { ENV } from '../../env';
import kp_axios from './api.keycloak.service';
import { IUserLogin } from '../../models/main/user.model';

export const STORAGE_SESSION_ID_KEY = 'sessionId';

export const loginUser = (data: IUserLogin) => {
  return kp_axios.post(`${ENV.API_URL}${ENV.ENGINE_ENDPOINT}/v2/webmanager/authentication`, data);
}

export const getUserSession = (sessionId: string) => {
  return kp_axios.get(`${ENV.API_URL}${ENV.ENGINE_ENDPOINT}/v2/webmanager/session?session=${sessionId}`);
}

export const endUserSession = (sessionId: string|null) => {
  return kp_axios.delete(`${ENV.API_URL}${ENV.ENGINE_ENDPOINT}/v2/webmanager/session/close?session=${sessionId}`);
}

export const changeUserLanguage = (sessionId: string, staffId: number, lang: string) => {
  let params = { "language" : lang };
  return kp_axios.patch(`${ENV.API_URL}${ENV.ENGINE_ENDPOINT}/v2/webmanager/staffs/${staffId}/profile-language?session=${sessionId}`, params);
}