import React from "react";
import ExplorerPage from '../explorer/explorer.page';
import ErrorPage from "../main/error/error.page";
import UserAccountPage from "../main/user/userAccount.page";
import SVGHome from "../../assets/icons/SVGHome";
import { financesRoutes, financesSideBar } from "./finances.routes";
import { rights } from "../../common/rights/rights";

//---------------------------------
// Common routes and sideBar items

const explorerRoutes = [
  {
    component: ExplorerPage,
    path: '/',
    name: 'explorer',
    right: "",
  },
]

const explorerSideBar = [
  {
    name: 'explorer',
    icon: <SVGHome />,
    isMultipleRoutes: false,
    path: '/',
    right: ""
  },
]

const userRoutes = [
  {
    component: UserAccountPage,
    path: '/userAccount',
    name: 'userAccount',
    right: rights.wm_myaccount_read,
  }
]

const errorRoutes = [
  {
    component: ErrorPage,
    path: "/error",
    name: "error",
    right: ""
  }
]

//---------------------------------
// All routes and sideBar items

export const tabSideBar = [
  ...explorerSideBar,
  ...financesSideBar,
]

export const allRoutes = [
  ...userRoutes,
  ...errorRoutes,
  ...explorerRoutes,
  ...financesRoutes,
]
