import './App.css';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Navbar from './component/main/navbar/navbar.comp';
import { Route, Routes } from "react-router-dom";
import { allRoutes, tabSideBar } from './pages/routes/routes';
import { Box } from '@mui/material';
import LoginPage from './pages/main/login/login.page';
import ErrorPage from './pages/main/error/error.page';
import TopBar from './component/main/topbar/topBar.comp';
import { APP_BODY_PADDING } from './common/style/commonStyle';
import { useDispatch } from 'react-redux';
import * as LoginActions from './store/actions/login.actions';
import * as loginService from './store/services/login.service';
import { ENV } from './env';
import { RootState } from './store/root.reducer';

function App() {

  const dispatch = useDispatch<any>();
  const isUserLogged = useSelector((state: RootState) => state.login.isLogged);
  const userRights = useSelector((state: RootState) => state.login.userRights);
  const [activePage, setActivePage] = useState(tabSideBar[0]);

  useEffect(() => {
    document.title = ENV.APP_NAME as string;
    dispatch(LoginActions.getUserSession())
  }, [])

  useEffect(() => {
    window.addEventListener("storage", onStorageUpdate);
    return () => {
      window.removeEventListener("storage", onStorageUpdate);
    }; 
  }, [])

  const onStorageUpdate = (e: any) => {
    const { key } = e;
    if (key === loginService.STORAGE_SESSION_ID_KEY) {
      if (!localStorage.getItem(key)) {
        dispatch({type: LoginActions.LOGIN_ACTIONS.HANDLE_LOGOUT_MULTIPLE_TABS})
      }
    }
  };

  const NoRightPage = () => (
    <ErrorPage errorType='noRight' />
  )

  const RoutesContainer = () => (
    <Routes>
      {allRoutes.map((route, i) => (
        <Route
          key={i}
          path={route.path}
          // exact={route.name === "explorer"}
          Component={
            (!route.right || userRights.includes(route.right))
              ? route.component
              : NoRightPage
          }
        />
      ))}
      <Route path='*' Component={ErrorPage} />
    </Routes>
  );

  return (
    <div className="App">
      {isUserLogged ?
        <Box sx={{ display: 'flex' }}>
          <Navbar setActivePage={setActivePage} />
          <Box sx={{ flex: 1 }}>
            <TopBar currentTab={activePage} />
            <Box component="main" sx={{ padding: `${APP_BODY_PADDING}px`, paddingTop: 0 }}>
              <RoutesContainer />
            </Box>
          </Box>
        </Box>
        :
        <LoginPage />
      }
    </div>
  );
}

export default App;
