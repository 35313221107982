import * as loginService from "../services/login.service";
import * as apiKeycloakService from "../services/api.keycloak.service";
import { setLanguage } from "../../common/i18n/i18n";
import { IUserLogin } from "../../models/main/user.model";
import { TypeDispatchArg, RootState } from "../root.reducer";

export const LOGIN_ACTIONS = {
  LOGIN_PENDING: 'LOGIN_PENDING',
  KEYCLOAK_AUTH_SUCCESSFULL: 'KEYCLOAK_AUTH_SUCCESSFULL',
  LOGIN_SUCCESSFULL: 'LOGIN_SUCCESSFULL',
  LOGIN_FAILED: 'LOGIN_FAILED',
  USER_LOGOUT: 'USER_LOGOUT',
  GET_ACTIVE_SESSION_PENDING: 'GET_ACTIVE_SESSION_PENDING',
  GET_ACTIVE_SESSION_SUCCESSFULL: 'GET_ACTIVE_SESSION_SUCCESSFULL',
  GET_ACTIVE_SESSION_FAILED: 'GET_ACTIVE_SESSION_FAILED',
  USER_LOGOUT_FAILED: 'USER_LOGOUT_FAILED',
  HANDLE_LOGOUT_MULTIPLE_TABS: 'HANDLE_LOGOUT_MULTIPLE_TABS',

  USER_CHANGE_LANGUAGE_SUCCESS: "USER_CHANGE_LANGUAGE_SUCCESS",
  USER_CHANGE_LANGUAGE_FAILED: "USER_CHANGE_LANGUAGE_FAILED"
}

export const loginUser = (data: IUserLogin) => {
  return async (dispatch: TypeDispatchArg) => {
    try {
      dispatch({ type: LOGIN_ACTIONS.LOGIN_PENDING });

      const r = await apiKeycloakService.requestKeycloakToken();
      if (r?.data) {
        dispatch({ type: LOGIN_ACTIONS.KEYCLOAK_AUTH_SUCCESSFULL, payload: r.data });
        const res = await loginService.loginUser(data);
        if (res?.data) {
          dispatch({ type: LOGIN_ACTIONS.LOGIN_SUCCESSFULL, payload: res.data })
          localStorage.setItem(loginService.STORAGE_SESSION_ID_KEY, res.data.session);
          setLanguage(res.data.language);
        }
      }
    } catch (err: any) {
      dispatch({ type: LOGIN_ACTIONS.LOGIN_FAILED, payload: err.response });
    }
  }
}

export const getUserSession = () => {
  return async (dispatch: TypeDispatchArg) => {
    try {
      const sessionId = localStorage.getItem(loginService.STORAGE_SESSION_ID_KEY);
      if (!sessionId) return;
      dispatch({ type: LOGIN_ACTIONS.GET_ACTIVE_SESSION_PENDING });

      const r = await apiKeycloakService.requestKeycloakToken();
      if (r?.data) {
        dispatch({ type: LOGIN_ACTIONS.KEYCLOAK_AUTH_SUCCESSFULL, payload: r.data });
        const res = await loginService.getUserSession(sessionId);
        if (res?.data) {
          dispatch({ type: LOGIN_ACTIONS.GET_ACTIVE_SESSION_SUCCESSFULL, payload: res.data })
          setLanguage(res.data.language);
        }
      }
    } catch (err: any) {
      dispatch({ type: LOGIN_ACTIONS.GET_ACTIVE_SESSION_FAILED, payload: err.response });
    }
  }
}

export const userLogout = () => {
  return async (dispatch: TypeDispatchArg) => {
    try {
      let sessionId: string|null;
      sessionId = localStorage.getItem(loginService.STORAGE_SESSION_ID_KEY);
      const res = await loginService.endUserSession(sessionId);
      if (res?.data.success) {
        dispatch({ type: LOGIN_ACTIONS.USER_LOGOUT });
        localStorage.removeItem(loginService.STORAGE_SESSION_ID_KEY);
      }
    } catch (err: any) {
      dispatch({ type: LOGIN_ACTIONS.USER_LOGOUT_FAILED, payload: err.response });
    }
  }
}

export const changeUserLanguage = (lang: any, callback: (arg0: boolean) => void) => {
  return async (dispatch: TypeDispatchArg, getState: RootState) => {
    const sessionId = getState().login?.userData?.session;
    const staffId = getState().login?.userData?.staffId;
    try {
      const res = await loginService.changeUserLanguage(sessionId, staffId, lang);
      if (res?.data.success) {
        callback(false)
        dispatch({ type: LOGIN_ACTIONS.USER_CHANGE_LANGUAGE_SUCCESS, payload: lang });
        setLanguage(lang);
      }
    } catch (err: any) {
      callback(true)
      dispatch({ type: LOGIN_ACTIONS.USER_CHANGE_LANGUAGE_FAILED, payload: err?.response });
    }
  }
}

